import { handleGraphqlResponse } from '@modules/handleGraphqlResponse';
import {
  GetAllAudienceSegmentQueriesQuery,
  GetSuggestedSegmentsStatsResult,
  Segments,
} from 'API';
import { StateCreator } from 'zustand';
import { generateClient } from 'aws-amplify/api';
import { getAllAudienceSegmentQueries } from '@graphql/queries';
import * as Sentry from '@sentry/nextjs';
import { DateRange } from 'react-day-picker';

export interface AudienceStateSlice {
  audiences: Segments[];
  setAudiences: (segments: Segments[]) => void;
  isAudienceLoading: boolean;
  fetchAudiences: (props: FetchAudiencesProps) => Promise<void>;
  record: number;
  isAudienceError: string | null;
  currentSelectedDateValue: DateRange | undefined;
  setCurrentSelectedDateValue: (value: DateRange | undefined) => void;
  dataFetched: boolean;
  setDataFetch: (fetchState: boolean) => void;
  segmentStats: GetSuggestedSegmentsStatsResult[];
  setSegmentStats: (state: GetSuggestedSegmentsStatsResult[]) => void;
  fetchPage: number;
}

export interface FetchAudiencesProps {
  limit: number;
  page: number;
  business_id: string;
  filter: {
    startDate: string;
    endDate: string;
  };
}

const client = generateClient();

export const createAudienceStore: StateCreator<AudienceStateSlice> = (set) => ({
  audiences: [],
  setAudiences: (segments) => set({ audiences: segments }),
  isAudienceLoading: true,
  record: 0,
  currentSelectedDateValue: undefined,
  isAudienceError: null,
  dataFetched: false,
  setDataFetch: (fetchState) => set({ dataFetched: fetchState }),
  fetchPage: 0,
  segmentStats: [],
  setSegmentStats: (state) => set({ segmentStats: state }),
  setCurrentSelectedDateValue: (value) =>
    set({ currentSelectedDateValue: value }),
  fetchAudiences: async (props) => {
    try {
      set({ isAudienceLoading: true });
      const response =
        await handleGraphqlResponse<GetAllAudienceSegmentQueriesQuery>(() =>
          client.graphql({
            query: getAllAudienceSegmentQueries,
            variables: {
              getAllAudienceSegmentQueryInput: {
                limit: props.limit,
                page: props.page,
                business_id: props.business_id,
                filter: {
                  startDate: props.filter.startDate,
                  endDate: props.filter.endDate,
                },
              },
            },
          }),
        );

      if (
        response.data &&
        response.message === 'Audience Segments Fetched Successfully' &&
        response.error === null
      ) {
        set({ audiences: response.data as Segments[] });
        set({ record: response.total_records as number });
        set({ isAudienceError: null });
        set({ dataFetched: true });
        set({ fetchPage: props.page });
        set({ isAudienceLoading: false });
      } else {
        Sentry.captureException(response.error?.message);
        set({ isAudienceError: response.error?.message });
      }
      set({ isAudienceLoading: false });
    } catch (error) {
      set({ isAudienceLoading: false });
      Sentry.captureException(error);
      let errorMessage = 'Unexpected error';
      if (error instanceof Error) {
        errorMessage = `Unable to perform action due to ${error.message} `;
      }
      set({ isAudienceError: errorMessage });
    }
  },
});
