import { StateCreator } from 'zustand';

interface State {
  showUnAuthorizedCallTooltip: boolean;
}

export const initialUnauthorizedCallSlice: State = {
  showUnAuthorizedCallTooltip: false,
};

interface Actions {
  setShowUnAuthorizedCallTooltip: (
    showUnAuthorizedCallTooltip: boolean,
  ) => void;
}

export interface UnauthorizedCallSlice extends State, Actions {}

export const createUnauthorizedCallSlice: StateCreator<
  UnauthorizedCallSlice,
  [],
  []
> = (set) => ({
  ...initialUnauthorizedCallSlice,
  setShowUnAuthorizedCallTooltip: (showUnAuthorizedCallTooltip) =>
    set((state) => ({ ...state, showUnAuthorizedCallTooltip })),
});
