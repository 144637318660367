/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const autoScalingSettingNew = /* GraphQL */ `query AutoScalingSettingNew {
  autoScalingSettingNew {
    error {
      code
      message
      __typename
    }
    message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AutoScalingSettingNewQueryVariables,
  APITypes.AutoScalingSettingNewQuery
>;
export const getBusinessTechStack = /* GraphQL */ `query GetBusinessTechStack(
  $getBusinessTechStackInput: GetBusinessTechStackInput!
) {
  getBusinessTechStack(getBusinessTechStackInput: $getBusinessTechStackInput) {
    data {
      business_id
      build_config
      created_at
      updated_at
      deleted_at
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBusinessTechStackQueryVariables,
  APITypes.GetBusinessTechStackQuery
>;
export const getBusinessBillingInfo = /* GraphQL */ `query GetBusinessBillingInfo(
  $getBusinessBillingInfoInput: GetBusinessBillingInfoInput!
) {
  getBusinessBillingInfo(
    getBusinessBillingInfoInput: $getBusinessBillingInfoInput
  ) {
    data {
      business_revenue
      business_orders_count
      plan_code
      plan_name
      plan_price
      next_billing_plan_code
      next_billing_plan_name
      next_billing_plan_price
      next_billing_date
      promo_code
      pricing_model
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBusinessBillingInfoQueryVariables,
  APITypes.GetBusinessBillingInfoQuery
>;
export const getDefaultTrackableCopy = /* GraphQL */ `query GetDefaultTrackableCopy($getDefaultTrackableCopyInput: BusinessIdInput!) {
  getDefaultTrackableCopy(
    getDefaultTrackableCopyInput: $getDefaultTrackableCopyInput
  ) {
    data {
      default_trackable_copy {
        id
        name
        description
        type
        short_code
        destination_url
        url_position
        business_id
        created
        stats {
          clicks
          purchases
          revenue
          __typename
        }
        is_onboarding_default
        __typename
      }
      has_manually_created_trackable_copy
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDefaultTrackableCopyQueryVariables,
  APITypes.GetDefaultTrackableCopyQuery
>;
export const getAllAdminTasks = /* GraphQL */ `query GetAllAdminTasks($getAllAdminTasksInput: GetAllAdminTasksInput!) {
  getAllAdminTasks(getAllAdminTasksInput: $getAllAdminTasksInput) {
    data {
      id
      title
      description
      priority
      objectives
      created_at
      updated_at
      deleted_at
      views
      completed
      is_task_master
      deeplink_target_url
      target_button_title
      task_image_url
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    numberPages
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllAdminTasksQueryVariables,
  APITypes.GetAllAdminTasksQuery
>;
export const getUserTasks = /* GraphQL */ `query GetUserTasks($getUserTasksInput: GetUserTasksInput) {
  getUserTasks(getUserTasksInput: $getUserTasksInput) {
    data {
      id
      title
      description
      priority
      objectives
      created_at
      updated_at
      deleted_at
      deeplink_target_url
      target_button_title
      task_image_url
      type
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    numberPages
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserTasksQueryVariables,
  APITypes.GetUserTasksQuery
>;
export const getHomepageMetrics = /* GraphQL */ `query GetHomepageMetrics($getHomepageMetricsInput: GetHomepageMetricsInput) {
  getHomepageMetrics(getHomepageMetricsInput: $getHomepageMetricsInput) {
    data {
      total_orders
      average_order_value
      total_refunds
      sirge_total_conversion_value
      total_sales
      blended_roas
      total_amount_spent
      total_amount_spent_to_date
      total_conversion_value
      monthly_budget
      ads_generating_revenue_percentage
      total_facebook_orders
      total_google_orders
      total_tiktok_orders
      break_even_roas
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetHomepageMetricsQueryVariables,
  APITypes.GetHomepageMetricsQuery
>;
export const getSalesBreakdown = /* GraphQL */ `query GetSalesBreakdown($getSalesBreakdownInput: GetSalesBreakdownInput) {
  getSalesBreakdown(getSalesBreakdownInput: $getSalesBreakdownInput) {
    data {
      total_sales
      total_revenue
      ads
      email
      social_media
      others
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSalesBreakdownQueryVariables,
  APITypes.GetSalesBreakdownQuery
>;
export const generateUploadUrlNew = /* GraphQL */ `query GenerateUploadUrlNew($generateUploadUrlInput: GenerateUploadUrlInput!) {
  generateUploadUrlNew(generateUploadUrlInput: $generateUploadUrlInput) {
    data {
      upload_url
      url
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GenerateUploadUrlNewQueryVariables,
  APITypes.GenerateUploadUrlNewQuery
>;
export const getMemberCountByAdGroupIDsNew = /* GraphQL */ `query GetMemberCountByAdGroupIDsNew(
  $getMemberCountByAdGroupIDsInput: GetMemberCountByAdGroupIDsInputNew!
) {
  getMemberCountByAdGroupIDsNew(
    getMemberCountByAdGroupIDsInput: $getMemberCountByAdGroupIDsInput
  ) {
    data {
      adSetCount
      adsCount
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMemberCountByAdGroupIDsNewQueryVariables,
  APITypes.GetMemberCountByAdGroupIDsNewQuery
>;
export const getAllStaffAccountsNew = /* GraphQL */ `query GetAllStaffAccountsNew(
  $getAllStaffAccountsInput: GetAllStaffAccountsInput
) {
  getAllStaffAccountsNew(getAllStaffAccountsInput: $getAllStaffAccountsInput) {
    data {
      id
      first_name
      last_name
      email
      address1
      password
      timezone
      currency
      created_at
      updated_at
      deleted_at
      businesses {
        user_id
        business_id
        user_type
        is_default_business
        created_at
        updated_at
        deleted_at
        __typename
      }
      profile_photo
      is_admin_user
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllStaffAccountsNewQueryVariables,
  APITypes.GetAllStaffAccountsNewQuery
>;
export const getAdGroupBudgetDetailsNew = /* GraphQL */ `query GetAdGroupBudgetDetailsNew(
  $getAdGroupBudgetDetailsInput: GetAdGroupBudgetDetailsInput!
) {
  getAdGroupBudgetDetailsNew(
    getAdGroupBudgetDetailsInput: $getAdGroupBudgetDetailsInput
  ) {
    data {
      adGroup {
        daily_budget
        lifetime_budget
        name
        source_delivery_status
        source_secondary_status
        shared_budget_name
        __typename
      }
      relatedItems {
        id
        source
        business_id
        purchases_count
        clicks_count
        daily_budget
        lifetime_budget
        budget
        shared_budget_name
        campaign_name
        campaign_budget {
          daily_budget
          lifetime_budget
          name
          source_delivery_status
          source_secondary_status
          shared_budget_name
          __typename
        }
        ad_set_budget {
          daily_budget
          lifetime_budget
          name
          source_delivery_status
          source_secondary_status
          shared_budget_name
          __typename
        }
        ad_images
        itemType
        ad_image
        ad_set
        ad_set_name
        ad
        ad_name
        sirge_ad_id
        sirge_adset_id
        sirge_campaign_id
        total_title
        source_delivery_status
        source_secondary_status
        clicks
        purchases
        purchases_source
        roas
        cost_per_purchase
        amount_spent
        average_cpm
        conversion_value
        total_conversion_value
        roas_ltv
        sirge_clicks
        sirge_purchases
        sirge_roas
        sirge_cost_per_purchase
        sirge_total_conversion_value
        created
        updated_at
        ad_type
        utm_status
        are_all_ads_connected
        transform
        __typename
      }
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdGroupBudgetDetailsNewQueryVariables,
  APITypes.GetAdGroupBudgetDetailsNewQuery
>;
export const getBusinessSourcesByIdNew = /* GraphQL */ `query GetBusinessSourcesByIdNew(
  $getBusinessSourcesByIdInput: GetBusinessSourcesByIdInput!
) {
  getBusinessSourcesByIdNew(
    getBusinessSourcesByIdInput: $getBusinessSourcesByIdInput
  ) {
    data {
      sources {
        source
        unique_visitor
        clicks_count
        purchases_count
        url
        created
        referer
        business_id
        __typename
      }
      totalInfo {
        total_clicks
        total_purchases
        totalrecords
        total_visitors
        total_pageviews
        __typename
      }
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    numberPages
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBusinessSourcesByIdNewQueryVariables,
  APITypes.GetBusinessSourcesByIdNewQuery
>;
export const getBusinessSourcesDetailsByIdNew = /* GraphQL */ `query GetBusinessSourcesDetailsByIdNew(
  $getBusinessSourcesDetailsByIdInput: GetBusinessSourcesDetailsByIdInput!
) {
  getBusinessSourcesDetailsByIdNew(
    getBusinessSourcesDetailsByIdInput: $getBusinessSourcesDetailsByIdInput
  ) {
    data {
      source
      unique_visitor
      clicks_count
      purchases_count
      url
      created
      referer
      business_id
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    numberPages
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBusinessSourcesDetailsByIdNewQueryVariables,
  APITypes.GetBusinessSourcesDetailsByIdNewQuery
>;
export const getBusinessGoogleTokenNew = /* GraphQL */ `query GetBusinessGoogleTokenNew(
  $getBusinessGoogleTokenNewInput: GetBusinessGoogleTokenNewInput!
) {
  getBusinessGoogleTokenNew(
    getBusinessGoogleTokenNewInput: $getBusinessGoogleTokenNewInput
  ) {
    data
    error {
      code
      message
      __typename
    }
    message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBusinessGoogleTokenNewQueryVariables,
  APITypes.GetBusinessGoogleTokenNewQuery
>;
export const getCurrentUserSessionsNew = /* GraphQL */ `query GetCurrentUserSessionsNew(
  $getCurrentUserSessionsInput: GetCurrentUserSessionsInput
) {
  getCurrentUserSessionsNew(
    getCurrentUserSessionsInput: $getCurrentUserSessionsInput
  ) {
    data {
      browser_name
      browser_version
      created_at
      ip
      location
      os_name
      os_version
      user_id
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCurrentUserSessionsNewQueryVariables,
  APITypes.GetCurrentUserSessionsNewQuery
>;
export const getAllPlatformBusinesses = /* GraphQL */ `query GetAllPlatformBusinesses(
  $getAllPlatformBusinessesInput: GetAllPlatformBusinessesInput!
) {
  getAllPlatformBusinesses(
    getAllPlatformBusinessesInput: $getAllPlatformBusinessesInput
  ) {
    data {
      businessList {
        id
        name
        logo
        manager_email
        manager_name
        vanity_name
        techStackTracked
        onboarding_call_booked
        onboarding_call_completed_at
        __typename
      }
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    numberPages
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllPlatformBusinessesQueryVariables,
  APITypes.GetAllPlatformBusinessesQuery
>;
export const fetchBusinessMetaData = /* GraphQL */ `query FetchBusinessMetaData($fetchBusinessMetaDataInput: BusinessIdInput!) {
  fetchBusinessMetaData(
    fetchBusinessMetaDataInput: $fetchBusinessMetaDataInput
  ) {
    data {
      id
      business_id
      tiktok_link
      twitter_link
      youtube_link
      instagram_link
      created_at
      updated_at
      deleted_at
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FetchBusinessMetaDataQueryVariables,
  APITypes.FetchBusinessMetaDataQuery
>;
export const getSocialAdAccounts = /* GraphQL */ `query GetSocialAdAccounts($getSocialAdAccountsInput: BusinessIdWithPlatform!) {
  getSocialAdAccounts(getSocialAdAccountsInput: $getSocialAdAccountsInput) {
    data {
      id
      currency
      name
      timezone
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSocialAdAccountsQueryVariables,
  APITypes.GetSocialAdAccountsQuery
>;
export const getStoreByShopName = /* GraphQL */ `query GetStoreByShopName($getStoreByShopNameInput: GetStoreByShopNameInput!) {
  getStoreByShopName(getStoreByShopNameInput: $getStoreByShopNameInput) {
    data {
      id
      platform_id
      shop_name
      admin_email
      address1
      address2
      city
      state
      zip
      country
      store_url
      access_token
      script_tag_id
      timezone
      currency
      script_installed
      script_uninstalled_date
      info
      created_at
      updated_at
      deleted_at
      business {
        id
        __typename
      }
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStoreByShopNameQueryVariables,
  APITypes.GetStoreByShopNameQuery
>;
export const getBusinessByShop = /* GraphQL */ `query GetBusinessByShop($getBusinessByShopInput: GetBusinessByShopInput!) {
  getBusinessByShop(getBusinessByShopInput: $getBusinessByShopInput) {
    data {
      id
      store_id
      store_url
      shop_name
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBusinessByShopQueryVariables,
  APITypes.GetBusinessByShopQuery
>;
export const getBusinessByShopUrl = /* GraphQL */ `query GetBusinessByShopUrl($getBusinessByShopInput: GetBusinessByShopInput!) {
  getBusinessByShopUrl(getBusinessByShopInput: $getBusinessByShopInput) {
    data {
      id
      store_id
      store_url
      shop_name
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBusinessByShopUrlQueryVariables,
  APITypes.GetBusinessByShopUrlQuery
>;
export const getUserByEmail = /* GraphQL */ `query GetUserByEmail($getUserByEmailInput: GetUserByEmailInput!) {
  getUserByEmail(getUserByEmailInput: $getUserByEmailInput) {
    data {
      id
      first_name
      last_name
      email
      address1
      address2
      city
      state
      zip
      country
      timezone
      currency
      profile_photo
      created_at
      updated_at
      deleted_at
      auto_scaling_setting
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserByEmailQueryVariables,
  APITypes.GetUserByEmailQuery
>;
export const checkUserExistsByShopUrl = /* GraphQL */ `query CheckUserExistsByShopUrl(
  $checkUserExistsByShopUrlInput: CheckUserExistsByShopUrlInput!
) {
  checkUserExistsByShopUrl(
    checkUserExistsByShopUrlInput: $checkUserExistsByShopUrlInput
  ) {
    data
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CheckUserExistsByShopUrlQueryVariables,
  APITypes.CheckUserExistsByShopUrlQuery
>;
export const getUserStoreSessions = /* GraphQL */ `query GetUserStoreSessions(
  $getUserStoreSessionsInput: GetUserStoreSessionsInput!
) {
  getUserStoreSessions(getUserStoreSessionsInput: $getUserStoreSessionsInput) {
    data {
      shop
      state
      isOnline
      scope
      expires
      accessToken
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserStoreSessionsQueryVariables,
  APITypes.GetUserStoreSessionsQuery
>;
export const getStoreDataForCallbackAuth = /* GraphQL */ `query GetStoreDataForCallbackAuth(
  $getStoreDataForCallbackAuthInput: GetStoreDataForCallbackAuthInput
) {
  getStoreDataForCallbackAuth(
    getStoreDataForCallbackAuthInput: $getStoreDataForCallbackAuthInput
  ) {
    data {
      first_name
      last_name
      email
      is_installed
      business_id
      user_exists
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStoreDataForCallbackAuthQueryVariables,
  APITypes.GetStoreDataForCallbackAuthQuery
>;
export const getStaffByIdNew = /* GraphQL */ `query GetStaffByIdNew($getStaffByIdInput: GetStaffByIdInput!) {
  getStaffByIdNew(getStaffByIdInput: $getStaffByIdInput) {
    data {
      id
      first_name
      last_name
      email
      address1
      password
      timezone
      currency
      created_at
      updated_at
      deleted_at
      businesses {
        user_id
        business_id
        business {
          id
          store_id
          name
          status
          logo
          vanity_name
          reminder_status
          show_budget_confirmation
          monthly_budget
          business_goal
          campaign_roas_goal
          adset_roas_goal
          ad_roas_goal
          created_at
          updated_at
          deleted_at
          ad_account_settings {
            id
            business_id
            ad_platform_id
            premium_page_views
            external_platform
            active_campaign_count
            paused_campaign_count
            social_account_id
            social_account_name
            social_account_currency
            social_account_timezone
            access_token
            utm_count
            social_integration
            conversion_api_enabled
            pixel_id
            social_refresh_token
            updateKey
            last_data_refreshed
            created_at
            updated_at
            deleted_at
            ad_platform {
              id
              name
              internal_source_name
              dark_theme_image_url
              light_theme_image_url
              status
              __typename
            }
            __typename
          }
          subscriptions {
            id
            business_id
            subscription_plan_code
            status
            processor
            store_subscription_body
            store_subscription_id
            promo_code_id
            trial_start
            trial_end
            trial_left
            current_billing_period_start
            current_billing_period_end
            subscription_end_date
            business_limit
            staff_limit
            subscription_charges {
              id
              business_id
              subscription_id
              store_revenue
              amount_billed
              created_at
              updated_at
              deleted_at
              __typename
            }
            promo_codes {
              id
              code
              type
              status
              duration
              amount
              created_at
              updated_at
              deleted_at
              __typename
            }
            current_revenue
            created_at
            updated_at
            deleted_at
            __typename
          }
          store {
            id
            shop_name
            address1
            address2
            city
            state
            zip
            country
            store_url
            timezone
            currency
            created_at
            updated_at
            deleted_at
            __typename
          }
          completed_onboarding_call
          has_visitor_session
          business_roas_goals {
            id
            roas_goal
            ad_platform_id
            ad_platforms {
              id
              name
              internal_source_name
              dark_theme_image_url
              light_theme_image_url
              status
              __typename
            }
            __typename
          }
          tour_completed_at
          tour_skipped_at
          feature_flags
          onboarding_call_completed_at
          __typename
        }
        user_type
        is_default_business
        created_at
        updated_at
        deleted_at
        __typename
      }
      profile_photo
      is_admin_user
      link_tracking_visit_count
      link_tracking_view_status
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStaffByIdNewQueryVariables,
  APITypes.GetStaffByIdNewQuery
>;
export const getUserNew = /* GraphQL */ `query GetUserNew($getUserInput: GetUserInput) {
  getUserNew(getUserInput: $getUserInput) {
    data {
      id
      first_name
      last_name
      email
      address1
      password
      timezone
      currency
      created_at
      updated_at
      deleted_at
      businesses {
        user_id
        business_id
        business {
          id
          store_id
          name
          status
          logo
          vanity_name
          reminder_status
          show_budget_confirmation
          monthly_budget
          business_goal
          campaign_roas_goal
          adset_roas_goal
          ad_roas_goal
          created_at
          updated_at
          deleted_at
          ad_account_settings {
            id
            business_id
            ad_platform_id
            premium_page_views
            external_platform
            active_campaign_count
            paused_campaign_count
            social_account_id
            social_account_name
            social_account_currency
            social_account_timezone
            access_token
            utm_count
            social_integration
            conversion_api_enabled
            pixel_id
            social_refresh_token
            updateKey
            last_data_refreshed
            created_at
            updated_at
            deleted_at
            ad_platform {
              id
              name
              internal_source_name
              dark_theme_image_url
              light_theme_image_url
              status
              __typename
            }
            __typename
          }
          subscriptions {
            id
            business_id
            subscription_plan_code
            status
            processor
            store_subscription_body
            store_subscription_id
            promo_code_id
            trial_start
            trial_end
            trial_left
            current_billing_period_start
            current_billing_period_end
            subscription_end_date
            business_limit
            staff_limit
            subscription_charges {
              id
              business_id
              subscription_id
              store_revenue
              amount_billed
              created_at
              updated_at
              deleted_at
              __typename
            }
            promo_codes {
              id
              code
              type
              status
              duration
              amount
              created_at
              updated_at
              deleted_at
              __typename
            }
            current_revenue
            created_at
            updated_at
            deleted_at
            __typename
          }
          store {
            id
            shop_name
            address1
            address2
            city
            state
            zip
            country
            store_url
            timezone
            currency
            created_at
            updated_at
            deleted_at
            __typename
          }
          completed_onboarding_call
          has_visitor_session
          business_roas_goals {
            id
            roas_goal
            ad_platform_id
            ad_platforms {
              id
              name
              internal_source_name
              dark_theme_image_url
              light_theme_image_url
              status
              __typename
            }
            __typename
          }
          tour_completed_at
          tour_skipped_at
          feature_flags
          onboarding_call_completed_at
          __typename
        }
        user_type
        is_default_business
        created_at
        updated_at
        deleted_at
        __typename
      }
      profile_photo
      is_admin_user
      link_tracking_visit_count
      link_tracking_view_status
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserNewQueryVariables,
  APITypes.GetUserNewQuery
>;
export const getPerformanceDetailsNew = /* GraphQL */ `query GetPerformanceDetailsNew(
  $getPerformanceDetailsInput: GetPerformanceDetailsInput
) {
  getPerformanceDetailsNew(
    getPerformanceDetailsInput: $getPerformanceDetailsInput
  ) {
    data {
      performance {
        id
        source
        business_id
        purchases_count
        clicks_count
        daily_budget
        lifetime_budget
        budget
        shared_budget_name
        campaign_name
        campaign_budget
        ad_set_budget
        ad_images
        itemType
        ad_image
        ad_set
        ad_set_name
        ad
        ad_name
        sirge_ad_id
        sirge_adset_id
        sirge_campaign_id
        total_title
        source_delivery_status
        source_secondary_status
        clicks
        purchases
        purchases_source
        roas
        cost_per_purchase
        amount_spent
        average_cpm
        conversion_value
        total_conversion_value
        roas_ltv
        sirge_clicks
        sirge_purchases
        sirge_roas
        sirge_cost_per_purchase
        sirge_total_conversion_value
        created
        updated_at
        ad_type
        utm_status
        are_all_ads_connected
        transform
        __typename
      }
      summary {
        amount_spent
        clicks
        purchases
        purchases_source
        cost_per_purchase
        total_conversion_value
        roas
        ft_clicks
        ft_purchases
        ft_cost_per_purchase
        ft_total_conversion_value
        ft_roas_ltv
        ft_roas
        ft_average_cpm
        number_of_records
        __typename
      }
      isRoasGoalsSet
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    numberPages
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPerformanceDetailsNewQueryVariables,
  APITypes.GetPerformanceDetailsNewQuery
>;
export const getBusinessConnectionsNew = /* GraphQL */ `query GetBusinessConnectionsNew(
  $getBusinessConnectionsInput: GetBusinessConnectionsInput!
) {
  getBusinessConnectionsNew(
    getBusinessConnectionsInput: $getBusinessConnectionsInput
  ) {
    data {
      facebook_ad_account_id
      facebook_ad_account_name
      script_installed
      shopify_store
      tik_tok_ad_account_id
      tik_tok_ad_account_name
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBusinessConnectionsNewQueryVariables,
  APITypes.GetBusinessConnectionsNewQuery
>;
export const getPurchasesByBusinessNew = /* GraphQL */ `query GetPurchasesByBusinessNew(
  $getPurchaseByBusinessInput: GetPurchaseByBusinessInput!
) {
  getPurchasesByBusinessNew(
    getPurchaseByBusinessInput: $getPurchaseByBusinessInput
  ) {
    data {
      customer_name
      customer_id
      created
      purchases_count
      conversion_value
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    numberPages
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPurchasesByBusinessNewQueryVariables,
  APITypes.GetPurchasesByBusinessNewQuery
>;
export const getUserDetails = /* GraphQL */ `query GetUserDetails($getUserDetailsInput: GetUserDetailsInput!) {
  getUserDetails(getUserDetailsInput: $getUserDetailsInput) {
    data {
      business {
        id
        store_id
        name
        status
        logo
        vanity_name
        reminder_status
        show_budget_confirmation
        monthly_budget
        business_goal
        campaign_roas_goal
        adset_roas_goal
        ad_roas_goal
        created_at
        updated_at
        deleted_at
        ad_account_settings {
          id
          business_id
          ad_platform_id
          premium_page_views
          external_platform
          active_campaign_count
          paused_campaign_count
          social_account_id
          social_account_name
          social_account_currency
          social_account_timezone
          access_token
          utm_count
          social_integration
          conversion_api_enabled
          pixel_id
          social_refresh_token
          updateKey
          last_data_refreshed
          created_at
          updated_at
          deleted_at
          ad_platform {
            id
            name
            internal_source_name
            dark_theme_image_url
            light_theme_image_url
            status
            __typename
          }
          __typename
        }
        subscriptions {
          id
          business_id
          subscription_plan_code
          status
          processor
          store_subscription_body
          store_subscription_id
          promo_code_id
          trial_start
          trial_end
          trial_left
          current_billing_period_start
          current_billing_period_end
          subscription_end_date
          business_limit
          staff_limit
          subscription_charges {
            id
            business_id
            subscription_id
            store_revenue
            amount_billed
            created_at
            updated_at
            deleted_at
            __typename
          }
          promo_codes {
            id
            code
            type
            status
            duration
            amount
            created_at
            updated_at
            deleted_at
            __typename
          }
          current_revenue
          created_at
          updated_at
          deleted_at
          __typename
        }
        store {
          id
          shop_name
          address1
          address2
          city
          state
          zip
          country
          store_url
          timezone
          currency
          created_at
          updated_at
          deleted_at
          __typename
        }
        completed_onboarding_call
        has_visitor_session
        business_roas_goals {
          id
          roas_goal
          ad_platform_id
          ad_platforms {
            id
            name
            internal_source_name
            dark_theme_image_url
            light_theme_image_url
            status
            __typename
          }
          __typename
        }
        tour_completed_at
        tour_skipped_at
        feature_flags
        onboarding_call_completed_at
        __typename
      }
      businesses {
        business_active_count
        business_count
        business_list {
          user_id
          business_id
          business {
            id
            store_id
            name
            status
            logo
            vanity_name
            reminder_status
            show_budget_confirmation
            monthly_budget
            business_goal
            campaign_roas_goal
            adset_roas_goal
            ad_roas_goal
            created_at
            updated_at
            deleted_at
            ad_account_settings {
              id
              business_id
              ad_platform_id
              premium_page_views
              external_platform
              active_campaign_count
              paused_campaign_count
              social_account_id
              social_account_name
              social_account_currency
              social_account_timezone
              access_token
              utm_count
              social_integration
              conversion_api_enabled
              pixel_id
              social_refresh_token
              updateKey
              last_data_refreshed
              created_at
              updated_at
              deleted_at
              ad_platform {
                id
                name
                internal_source_name
                dark_theme_image_url
                light_theme_image_url
                status
                __typename
              }
              __typename
            }
            subscriptions {
              id
              business_id
              subscription_plan_code
              status
              processor
              store_subscription_body
              store_subscription_id
              promo_code_id
              trial_start
              trial_end
              trial_left
              current_billing_period_start
              current_billing_period_end
              subscription_end_date
              business_limit
              staff_limit
              subscription_charges {
                id
                business_id
                subscription_id
                store_revenue
                amount_billed
                created_at
                updated_at
                deleted_at
                __typename
              }
              promo_codes {
                id
                code
                type
                status
                duration
                amount
                created_at
                updated_at
                deleted_at
                __typename
              }
              current_revenue
              created_at
              updated_at
              deleted_at
              __typename
            }
            store {
              id
              shop_name
              address1
              address2
              city
              state
              zip
              country
              store_url
              timezone
              currency
              created_at
              updated_at
              deleted_at
              __typename
            }
            completed_onboarding_call
            has_visitor_session
            business_roas_goals {
              id
              roas_goal
              ad_platform_id
              ad_platforms {
                id
                name
                internal_source_name
                dark_theme_image_url
                light_theme_image_url
                status
                __typename
              }
              __typename
            }
            tour_completed_at
            tour_skipped_at
            feature_flags
            onboarding_call_completed_at
            __typename
          }
          user_type
          is_default_business
          created_at
          updated_at
          deleted_at
          __typename
        }
        __typename
      }
      status {
        active
        __typename
      }
      user {
        id
        first_name
        last_name
        email
        address1
        password
        timezone
        currency
        created_at
        updated_at
        deleted_at
        businesses {
          user_id
          business_id
          business {
            id
            store_id
            name
            status
            logo
            vanity_name
            reminder_status
            show_budget_confirmation
            monthly_budget
            business_goal
            campaign_roas_goal
            adset_roas_goal
            ad_roas_goal
            created_at
            updated_at
            deleted_at
            ad_account_settings {
              id
              business_id
              ad_platform_id
              premium_page_views
              external_platform
              active_campaign_count
              paused_campaign_count
              social_account_id
              social_account_name
              social_account_currency
              social_account_timezone
              access_token
              utm_count
              social_integration
              conversion_api_enabled
              pixel_id
              social_refresh_token
              updateKey
              last_data_refreshed
              created_at
              updated_at
              deleted_at
              ad_platform {
                id
                name
                internal_source_name
                dark_theme_image_url
                light_theme_image_url
                status
                __typename
              }
              __typename
            }
            subscriptions {
              id
              business_id
              subscription_plan_code
              status
              processor
              store_subscription_body
              store_subscription_id
              promo_code_id
              trial_start
              trial_end
              trial_left
              current_billing_period_start
              current_billing_period_end
              subscription_end_date
              business_limit
              staff_limit
              subscription_charges {
                id
                business_id
                subscription_id
                store_revenue
                amount_billed
                created_at
                updated_at
                deleted_at
                __typename
              }
              promo_codes {
                id
                code
                type
                status
                duration
                amount
                created_at
                updated_at
                deleted_at
                __typename
              }
              current_revenue
              created_at
              updated_at
              deleted_at
              __typename
            }
            store {
              id
              shop_name
              address1
              address2
              city
              state
              zip
              country
              store_url
              timezone
              currency
              created_at
              updated_at
              deleted_at
              __typename
            }
            completed_onboarding_call
            has_visitor_session
            business_roas_goals {
              id
              roas_goal
              ad_platform_id
              ad_platforms {
                id
                name
                internal_source_name
                dark_theme_image_url
                light_theme_image_url
                status
                __typename
              }
              __typename
            }
            tour_completed_at
            tour_skipped_at
            feature_flags
            onboarding_call_completed_at
            __typename
          }
          user_type
          is_default_business
          created_at
          updated_at
          deleted_at
          __typename
        }
        profile_photo
        is_admin_user
        link_tracking_visit_count
        link_tracking_view_status
        __typename
      }
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserDetailsQueryVariables,
  APITypes.GetUserDetailsQuery
>;
export const getUserFacebookAccountNew = /* GraphQL */ `query GetUserFacebookAccountNew(
  $getUserFacebookAccountInput: GetUserFacebookAccountInput!
) {
  getUserFacebookAccountNew(
    getUserFacebookAccountInput: $getUserFacebookAccountInput
  ) {
    data {
      id
      name
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserFacebookAccountNewQueryVariables,
  APITypes.GetUserFacebookAccountNewQuery
>;
export const getDiscountCodeStatusNew = /* GraphQL */ `query GetDiscountCodeStatusNew(
  $getDiscountCodeStatusInput: GetDiscountCodeStatusInput!
) {
  getDiscountCodeStatusNew(
    getDiscountCodeStatusInput: $getDiscountCodeStatusInput
  ) {
    data {
      id
      code
      type
      status
      duration
      amount
      created_at
      updated_at
      deleted_at
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDiscountCodeStatusNewQueryVariables,
  APITypes.GetDiscountCodeStatusNewQuery
>;
export const getKeyMetricsStatisticsGraph = /* GraphQL */ `query GetKeyMetricsStatisticsGraph(
  $getKeyMetricsAnalyticsStatisticsInput: GetKeyMetricsAnalyticsStatisticsInput!
) {
  getKeyMetricsStatisticsGraph(
    getKeyMetricsAnalyticsStatisticsInput: $getKeyMetricsAnalyticsStatisticsInput
  ) {
    data {
      orders {
        date
        value
        __typename
      }
      amount_spent {
        date
        value
        __typename
      }
      store_visits {
        date
        value
        __typename
      }
      total_sales {
        date
        value
        __typename
      }
      ad_clicks {
        date
        value
        __typename
      }
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeyMetricsStatisticsGraphQueryVariables,
  APITypes.GetKeyMetricsStatisticsGraphQuery
>;
export const getShopifyScopesStatusNew = /* GraphQL */ `query GetShopifyScopesStatusNew {
  getShopifyScopesStatusNew {
    data
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetShopifyScopesStatusNewQueryVariables,
  APITypes.GetShopifyScopesStatusNewQuery
>;
export const getAccountsCampaignsNew = /* GraphQL */ `query GetAccountsCampaignsNew(
  $getAccountsCampaignsInput: GetAccountsCampaignsInput
) {
  getAccountsCampaignsNew(
    getAccountsCampaignsInput: $getAccountsCampaignsInput
  ) {
    data {
      facebook {
        id
        name
        status
        utm_status
        utm_injection_supported
        __typename
      }
      tiktok {
        id
        name
        status
        utm_status
        utm_injection_supported
        __typename
      }
      google {
        id
        name
        status
        utm_status
        utm_injection_supported
        __typename
      }
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountsCampaignsNewQueryVariables,
  APITypes.GetAccountsCampaignsNewQuery
>;
export const getPerformanceDrawerBasicDetailsNew = /* GraphQL */ `query GetPerformanceDrawerBasicDetailsNew(
  $getPerformanceDrawerBasicDetailsInput: GetPerformanceDrawerBasicDetailsInput
) {
  getPerformanceDrawerBasicDetailsNew(
    getPerformanceDrawerBasicDetailsInput: $getPerformanceDrawerBasicDetailsInput
  ) {
    data {
      id
      name
      source
      source_delivery_status
      source_secondary_status
      no_of_ads
      no_of_adsets
      utm_status
      are_all_ads_connected
      daily_budget
      shared_budget_name
      lifetime_budget
      campaign_budget
      ad_set_budget
      ad_images
      ad_type
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    numberPages
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPerformanceDrawerBasicDetailsNewQueryVariables,
  APITypes.GetPerformanceDrawerBasicDetailsNewQuery
>;
export const getPerformanceDrawerStatsNew = /* GraphQL */ `query GetPerformanceDrawerStatsNew(
  $getPerformanceDrawerStatsInput: GetPerformanceDrawerStatsInput
) {
  getPerformanceDrawerStatsNew(
    getPerformanceDrawerStatsInput: $getPerformanceDrawerStatsInput
  ) {
    data {
      total_amount_spent
      total_roas
      total_conversion
      total_purchases
      average_cpm
      graph {
        total_amount_spent
        total_conversion_value
        created
        __typename
      }
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    numberPages
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPerformanceDrawerStatsNewQueryVariables,
  APITypes.GetPerformanceDrawerStatsNewQuery
>;
export const generateTrackableCopyPathNew = /* GraphQL */ `query GenerateTrackableCopyPathNew(
  $generateTrackableCopyParams: BusinessIdInput!
) {
  generateTrackableCopyPathNew(
    generateTrackableCopyParams: $generateTrackableCopyParams
  ) {
    data {
      path
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GenerateTrackableCopyPathNewQueryVariables,
  APITypes.GenerateTrackableCopyPathNewQuery
>;
export const getTrackableCopiesNew = /* GraphQL */ `query GetTrackableCopiesNew(
  $getTrackableCopiesInput: GetTrackableCopiesInput!
) {
  getTrackableCopiesNew(getTrackableCopiesInput: $getTrackableCopiesInput) {
    data {
      id
      name
      description
      type
      short_code
      destination_url
      url_position
      business_id
      created
      stats {
        clicks
        purchases
        revenue
        __typename
      }
      is_onboarding_default
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    numberPages
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTrackableCopiesNewQueryVariables,
  APITypes.GetTrackableCopiesNewQuery
>;
export const getTrackableCopyTypesNew = /* GraphQL */ `query GetTrackableCopyTypesNew($getTrackableCopyTypesInput: BusinessIdInput!) {
  getTrackableCopyTypesNew(
    getTrackableCopyTypesInput: $getTrackableCopyTypesInput
  ) {
    data
    error {
      code
      message
      __typename
    }
    message
    nextToken
    numberPages
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTrackableCopyTypesNewQueryVariables,
  APITypes.GetTrackableCopyTypesNewQuery
>;
export const getTrackableCopyOrdersNew = /* GraphQL */ `query GetTrackableCopyOrdersNew(
  $getTrackableCopyOrdersInput: GetTrackableCopyOrdersInput!
) {
  getTrackableCopyOrdersNew(
    getTrackableCopyOrdersInput: $getTrackableCopyOrdersInput
  ) {
    data {
      business_id
      conversion_value
      created
      order_id
      source
      customer_id
      customer_name
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    numberPages
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTrackableCopyOrdersNewQueryVariables,
  APITypes.GetTrackableCopyOrdersNewQuery
>;
export const getKeyMetricsMonthlyBudget = /* GraphQL */ `query GetKeyMetricsMonthlyBudget(
  $getKeyMetricsMonthlyBudgetInput: GetKeyMetricsMonthlyBudgetInput!
) {
  getKeyMetricsMonthlyBudget(
    getKeyMetricsMonthlyBudgetInput: $getKeyMetricsMonthlyBudgetInput
  ) {
    data {
      amount_spent
      source
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeyMetricsMonthlyBudgetQueryVariables,
  APITypes.GetKeyMetricsMonthlyBudgetQuery
>;
export const getKeyMetricsRoasTracker = /* GraphQL */ `query GetKeyMetricsRoasTracker(
  $getKeyMetricsRoasTrackerInput: GetKeyMetricsRoasTrackerInput!
) {
  getKeyMetricsRoasTracker(
    getKeyMetricsRoasTrackerInput: $getKeyMetricsRoasTrackerInput
  ) {
    data {
      facebook {
        over
        under
        total
        __typename
      }
      tiktok {
        over
        under
        total
        __typename
      }
      google {
        over
        under
        total
        __typename
      }
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeyMetricsRoasTrackerQueryVariables,
  APITypes.GetKeyMetricsRoasTrackerQuery
>;
export const getAdcomparisonData = /* GraphQL */ `query GetAdcomparisonData(
  $getBusinessAnalyticsInput: GetBusinessAnalyticsInput!
) {
  getAdcomparisonData(getBusinessAnalyticsInput: $getBusinessAnalyticsInput) {
    data {
      id
      ad_id
      ad_name
      ad_primary_status
      ad_secondary_status
      total_amount_spent
      roas
      total_orders
      total_conversion_value
      ad_images
      source
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    numberPages
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdcomparisonDataQueryVariables,
  APITypes.GetAdcomparisonDataQuery
>;
export const getAllCustomersNew = /* GraphQL */ `query GetAllCustomersNew($getAllCustomersInput: GetAllCustomersInput!) {
  getAllCustomersNew(getAllCustomersInput: $getAllCustomersInput) {
    data {
      customers {
        last_visit
        first_visit
        total_purchases
        total_pageviews
        customer_id
        customer_name
        __typename
      }
      totalInfo {
        total_purchases
        totalrecords
        total_visitors
        total_pageviews
        total_clicks
        total_customers
        __typename
      }
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    numberPages
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllCustomersNewQueryVariables,
  APITypes.GetAllCustomersNewQuery
>;
export const getCustomerJourneyVisitorGraphNew = /* GraphQL */ `query GetCustomerJourneyVisitorGraphNew(
  $getCustomerJourneyVisitorGraphInput: GetCustomerJourneyVisitorGraphInput!
) {
  getCustomerJourneyVisitorGraphNew(
    getCustomerJourneyVisitorGraphInput: $getCustomerJourneyVisitorGraphInput
  ) {
    data {
      date
      new_visitors
      returning_visitors
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerJourneyVisitorGraphNewQueryVariables,
  APITypes.GetCustomerJourneyVisitorGraphNewQuery
>;
export const getSegmentBuilderBasicDetails = /* GraphQL */ `query GetSegmentBuilderBasicDetails(
  $getSegmentBuilderBasicDetailsInput: GetSegmentBuilderBasicDetailsInput!
) {
  getSegmentBuilderBasicDetails(
    getSegmentBuilderBasicDetailsInput: $getSegmentBuilderBasicDetailsInput
  ) {
    data {
      cities
      states
      countries
      dynamicQueryRules
      predefinedQueries
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSegmentBuilderBasicDetailsQueryVariables,
  APITypes.GetSegmentBuilderBasicDetailsQuery
>;
export const getAudienceSegmentQueryById = /* GraphQL */ `query GetAudienceSegmentQueryById(
  $getAudienceSegmentInput: GetAudienceSegmentInput!
) {
  getAudienceSegmentQueryById(
    getAudienceSegmentInput: $getAudienceSegmentInput
  ) {
    data {
      id
      business_id
      no_of_customers
      added_revenue
      audience
      type
      status
      query_details
      created_at
      updated_at
      group_name
      deleted_at
      dynamic_query_params
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAudienceSegmentQueryByIdQueryVariables,
  APITypes.GetAudienceSegmentQueryByIdQuery
>;
export const getCustomerJourneySourcesNew = /* GraphQL */ `query GetCustomerJourneySourcesNew(
  $getCustomerJourneySourcesInput: GetCustomerJourneySourcesInput!
) {
  getCustomerJourneySourcesNew(
    getCustomerJourneySourcesInput: $getCustomerJourneySourcesInput
  ) {
    data {
      sources {
        source
        unique_visitor
        unique_sessions
        purchases_count
        __typename
      }
      totalInfo {
        total_purchases
        totalrecords
        total_visitors
        total_pageviews
        total_clicks
        total_customers
        __typename
      }
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    numberPages
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerJourneySourcesNewQueryVariables,
  APITypes.GetCustomerJourneySourcesNewQuery
>;
export const getCustomerDetailsSourcesNew = /* GraphQL */ `query GetCustomerDetailsSourcesNew(
  $getCustomerDetailsSourcesInput: GetCustomerDetailsSourcesInput!
) {
  getCustomerDetailsSourcesNew(
    getCustomerDetailsSourcesInput: $getCustomerDetailsSourcesInput
  ) {
    data {
      channel_name
      referer
      created_at
      __typename
    }
    total_records
    numberPages
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerDetailsSourcesNewQueryVariables,
  APITypes.GetCustomerDetailsSourcesNewQuery
>;
export const getCustomerDetailsNew = /* GraphQL */ `query GetCustomerDetailsNew(
  $getCustomerDetailsInput: GetCustomerDetailsInput!
) {
  getCustomerDetailsNew(getCustomerDetailsInput: $getCustomerDetailsInput) {
    data {
      customer_email
      country
      state
      city
      total_pageviews
      first_visit
      total_purchases
      total_purchase_conversion_value
      customer_name
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerDetailsNewQueryVariables,
  APITypes.GetCustomerDetailsNewQuery
>;
export const getCustomerDetailsPageViewsNew = /* GraphQL */ `query GetCustomerDetailsPageViewsNew(
  $getCustomerDetailsPageViewsInput: getCustomerDetailsPageViewsInput!
) {
  getCustomerDetailsPageViewsNew(
    getCustomerDetailsPageViewsInput: $getCustomerDetailsPageViewsInput
  ) {
    data {
      ad
      ad_set
      campaign
      conversion_value
      created
      referer
      sirge_source_name
      url
      __typename
    }
    total_records
    numberPages
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerDetailsPageViewsNewQueryVariables,
  APITypes.GetCustomerDetailsPageViewsNewQuery
>;
export const getKeyMetricsAnalyticsStatistics = /* GraphQL */ `query GetKeyMetricsAnalyticsStatistics(
  $getKeyMetricsAnalyticsStatisticsInput: GetKeyMetricsAnalyticsStatisticsInput!
) {
  getKeyMetricsAnalyticsStatistics(
    getKeyMetricsAnalyticsStatisticsInput: $getKeyMetricsAnalyticsStatisticsInput
  ) {
    data {
      total_order {
        amount
        percentage
        trend {
          date
          value
          __typename
        }
        __typename
      }
      total_sales {
        amount
        percentage
        trend {
          date
          value
          __typename
        }
        __typename
      }
      average_order_value {
        amount
        percentage
        trend {
          date
          value
          __typename
        }
        __typename
      }
      blended_roas {
        amount
        percentage
        trend {
          date
          value
          __typename
        }
        sources {
          facebook
          tiktok
          google
          __typename
        }
        __typename
      }
      visits {
        amount
        percentage
        trend {
          date
          value
          __typename
        }
        __typename
      }
      conversion_rate {
        amount
        percentage
        trend {
          date
          value
          __typename
        }
        __typename
      }
      total_conversion_value {
        amount
        percentage
        trend {
          date
          value
          __typename
        }
        __typename
      }
      total_refunds {
        amount
        percentage
        trend {
          date
          value
          __typename
        }
        __typename
      }
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeyMetricsAnalyticsStatisticsQueryVariables,
  APITypes.GetKeyMetricsAnalyticsStatisticsQuery
>;
export const getCustomerChannelsNew = /* GraphQL */ `query GetCustomerChannelsNew(
  $getCustomerChannelsInput: GetCustomerChannelsInput!
) {
  getCustomerChannelsNew(getCustomerChannelsInput: $getCustomerChannelsInput) {
    data {
      channels {
        source
        source_id
        referer
        unique_visitor
        unique_sessions
        purchases_count
        __typename
      }
      totalInfo {
        total_clicks
        total_purchases
        totalrecords
        total_visitors
        total_pageviews
        __typename
      }
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    numberPages
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerChannelsNewQueryVariables,
  APITypes.GetCustomerChannelsNewQuery
>;
export const getCustomerChannelsDetailsNew = /* GraphQL */ `query GetCustomerChannelsDetailsNew(
  $getCustomerChannelsDetailsInput: GetCustomerChannelsDetailsInput!
) {
  getCustomerChannelsDetailsNew(
    getCustomerChannelsDetailsInput: $getCustomerChannelsDetailsInput
  ) {
    data {
      channels {
        source
        source_id
        referer
        unique_visitor
        unique_sessions
        purchases_count
        __typename
      }
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerChannelsDetailsNewQueryVariables,
  APITypes.GetCustomerChannelsDetailsNewQuery
>;
export const getSourcesOrdersNew = /* GraphQL */ `query GetSourcesOrdersNew($getSourcesOrdersInput: GetSourcesOrdersInput!) {
  getSourcesOrdersNew(getSourcesOrdersInput: $getSourcesOrdersInput) {
    data {
      business_id
      conversion_value
      created
      order_id
      source
      customer_id
      customer_name
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    numberPages
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSourcesOrdersNewQueryVariables,
  APITypes.GetSourcesOrdersNewQuery
>;
export const getSuggestedSegmentsStats = /* GraphQL */ `query GetSuggestedSegmentsStats(
  $getSuggestedSegmentsStatsInput: GetSuggestedSegmentsStatsInput!
) {
  getSuggestedSegmentsStats(
    getSuggestedSegmentsStatsInput: $getSuggestedSegmentsStatsInput
  ) {
    data {
      no_of_customers
      query_type
      __typename
    }
    total_records
    message
    error {
      code
      message
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSuggestedSegmentsStatsQueryVariables,
  APITypes.GetSuggestedSegmentsStatsQuery
>;
export const getCustomerOrdersByCustomerId = /* GraphQL */ `query GetCustomerOrdersByCustomerId(
  $getCustomerOrdersInput: GetCustomerOrdersInput!
) {
  getCustomerOrdersByCustomerId(
    getCustomerOrdersInput: $getCustomerOrdersInput
  ) {
    data {
      id
      customer_id
      customer_name
      platform_order_id
      city
      state
      zip
      country
      currency
      conversion_value
      insight_date
      visitor_id
      deleted_at
      updated_at
      created_at
      __typename
    }
    total_records
    message
    error {
      code
      message
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerOrdersByCustomerIdQueryVariables,
  APITypes.GetCustomerOrdersByCustomerIdQuery
>;
export const getAllStaticAudienceSegments = /* GraphQL */ `query GetAllStaticAudienceSegments(
  $getAllStaticAudienceSegmentQueryInput: GetAllStaticAudienceSegmentQueryInput!
) {
  getAllStaticAudienceSegments(
    getAllStaticAudienceSegmentQueryInput: $getAllStaticAudienceSegmentQueryInput
  ) {
    data {
      id
      business_id
      group_name
      __typename
    }
    total_records
    message
    error {
      code
      message
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllStaticAudienceSegmentsQueryVariables,
  APITypes.GetAllStaticAudienceSegmentsQuery
>;
export const getAllAudienceSegmentQueries = /* GraphQL */ `query GetAllAudienceSegmentQueries(
  $getAllAudienceSegmentQueryInput: GetAllAudienceSegmentQueryInput!
) {
  getAllAudienceSegmentQueries(
    getAllAudienceSegmentQueryInput: $getAllAudienceSegmentQueryInput
  ) {
    data {
      id
      business_id
      no_of_customers
      added_revenue
      audience
      type
      status
      query_details
      created_at
      updated_at
      group_name
      deleted_at
      dynamic_query_params
      __typename
    }
    total_records
    message
    error {
      code
      message
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllAudienceSegmentQueriesQueryVariables,
  APITypes.GetAllAudienceSegmentQueriesQuery
>;
export const getBusinessAIReport = /* GraphQL */ `query GetBusinessAIReport(
  $getBusinessAIReportInput: GetBusinessAIReportInput!
) {
  getBusinessAIReport(getBusinessAIReportInput: $getBusinessAIReportInput) {
    data
    error {
      code
      message
      __typename
    }
    message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBusinessAIReportQueryVariables,
  APITypes.GetBusinessAIReportQuery
>;
