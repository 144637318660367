import { GraphQLResult } from 'aws-amplify/api';
import { UnknownGraphQLResponse } from '@aws-amplify/api-graphql';
import { CacheCall, clearCacheKey } from './cacheCall';
import { useBoundStore } from '@store/index';

type ExtractFirstChildSubtype<T> = T extends {
  [key: string]: infer U | null | undefined;
}
  ? U
  : never;

export const handleGraphqlResponse = async <T>(
  operation: () => Promise<GraphQLResult<T>> | UnknownGraphQLResponse,
  key?: string,
): Promise<ExtractFirstChildSubtype<T>> => {
  try {
    let response = null;
    if (key) {
      response = await CacheCall(operation, key);
    } else {
      response = await operation();
    }

    if (!isGraphQLResult<T>(response)) {
      if (key) await clearCacheKey(key);
      throw new Error('Invalid GraphQL response type');
    }

    if (!response.data) {
      throw new Error('Response data is empty');
    }


    const [mainDataKey] = Object.keys(response.data);
    const mainData = (response.data as any)[mainDataKey];

    if (!mainData) {
      throw new Error('Main data could not be determined');
    }

    return mainData;
  } catch (error) {
    if (key) await clearCacheKey(key);

    if ((error as any)?.errors?.[0]?.errorType === 'Unauthorized') {
      const { setSnackBar } = useBoundStore.getState();

      setSnackBar({
        display: true,
        message: 'You can’t make any changes and updates',
        severity: 'error',
      });
    }

    throw error;
  }
};

const isGraphQLResult = <T>(
  response: GraphQLResult<T> | UnknownGraphQLResponse,
): response is GraphQLResult<T> =>
  typeof response === 'object' && 'data' in response;
