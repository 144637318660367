import { StateCreator } from 'zustand';

interface State {
  dynamicRules: any;
  cities: string[];
  countries: string[];
  states: string[];
  isLoading: boolean;
  predefinedQueries: any;
}

export const initialBasicDetail: State = {
  dynamicRules: [],
  cities: [],
  countries: [],
  states: [],
  isLoading: false,
  predefinedQueries: {},
};

interface Actions {
  setDynamicRules: (dynamicRules: any) => void;
  setCities: (cities: string[]) => void;
  setCountries: (countries: string[]) => void;
  setStates: (states: string[]) => void;
  setIsLoading: (isLoading: boolean) => void;
  setPredefinedQueries: (predefinedQueries: any) => void;
}

export interface BasicDetailSlice extends State, Actions {}

export const createBasicDetailSlice: StateCreator<BasicDetailSlice, [], []> = (
  set,
) => ({
  ...initialBasicDetail,
  setDynamicRules: (dynamicRules) =>
    set((state) => ({ ...state, dynamicRules })),
  setCities: (cities) => set((state) => ({ ...state, cities })),
  setCountries: (countries) => set((state) => ({ ...state, countries })),
  setStates: (states) => set((state) => ({ ...state, states })),
  setIsLoading: (isLoading) => set((state) => ({ ...state, isLoading })),
  setPredefinedQueries: (predefinedQueries) =>
    set((state) => ({ ...state, predefinedQueries })),
});
