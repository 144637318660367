import { AllPlatformBusiness } from 'API';
import { StateCreator } from 'zustand';

interface State {
  selectedAdminBusiness: AllPlatformBusiness | null;
}

export const initialAdminSelectedBusinessSlice: State = {
  selectedAdminBusiness: null,
};

interface Actions {
  setAdminSelectedBusiness: (
    selectedAdminBusiness: AllPlatformBusiness | null,
  ) => void;
}

export interface AdminSelectedBusinessSlice extends State, Actions {}

export const createAdminSelectedBusinessSlice: StateCreator<
  AdminSelectedBusinessSlice,
  [],
  []
> = (set) => ({
  ...initialAdminSelectedBusinessSlice,
  setAdminSelectedBusiness: (selectedAdminBusiness) =>
    set((state) => ({ ...state, selectedAdminBusiness })),
});
