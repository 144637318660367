import { StateCreator } from 'zustand';
import * as Sentry from '@sentry/nextjs';
import dayjs from 'dayjs';
import { onDataRefreshedForBusiness } from '@graphql/subscriptions';
import { BusinessProfileSlice } from './businessProfileSlice';
import { triggerBusinessDataLongFetchNew } from '@graphql/mutations';
import { generateClient } from 'aws-amplify/api';
import { Subscription } from 'rxjs';
import { handleGraphqlResponse } from '@modules/handleGraphqlResponse';
import {
  OnDataRefreshedForBusinessSubscription,
  TriggerBusinessDataLongFetchNewMutation,
} from 'API';
import { GraphqlSubscriptionResult } from '@aws-amplify/api-graphql';
import { clearCache } from '@modules/cacheCall';

export interface AsyncDataFetchSlice {
  fetchComplete: boolean;
  setFetchComplete: (fetchComplete: boolean) => void;
  asyncFetchLoading: boolean;
  setAsyncFetchLoading: (asyncFetchLoading: boolean) => void;
  displayLoader: boolean;
  setDisplayLoader: (displayLoader: boolean) => void;
  subscriptionHandler?: Subscription;
  setSubscriptionHandler: (subscriptionHandler: Subscription) => void;
  listenerCount: number;
  triggerFetch: (
    businessId: string,
    refreshAferFetch?: boolean,
  ) => Promise<void>;
  addListener: () => void;
  removeListener: () => void;
}

const client = generateClient();

export const createAsyncDataFetchSlice: StateCreator<
  AsyncDataFetchSlice & Partial<BusinessProfileSlice>,
  [],
  []
> = (set, get) => ({
  fetchComplete: false,
  setFetchComplete: (fetchComplete) =>
    set((state) => ({ ...state, fetchComplete })),
  asyncFetchLoading: false,
  setAsyncFetchLoading: (asyncFetchLoading) =>
    set((state) => ({ ...state, asyncFetchLoading })),
  displayLoader: false,
  setDisplayLoader: (displayLoader) =>
    set((state) => ({ ...state, displayLoader })),
  subscriptionHandler: undefined,
  setSubscriptionHandler: (subscriptionHandler) =>
    set((state) => ({ ...state, subscriptionHandler })),
  listenerCount: 0,
  triggerFetch: async (businessId: string, refreshAferFetch = true) => {
    try {
      const subscriptionHandler = get().subscriptionHandler;
      if (subscriptionHandler) {
        subscriptionHandler.unsubscribe();
      }

      set((state) => ({ ...state, asyncFetchLoading: true }));
      set((state) => ({ ...state, displayLoader: true }));
      set((state) => ({ ...state, fetchComplete: false }));

      // Timeout to automatically close the data loader after 10s
      setTimeout(() => {
        set((state) => ({
          ...state,
          asyncFetchLoading: false,
        }));
        setTimeout(() => {
          set((state) => ({
            ...state,
            displayLoader: false,
          }));
        }, 1000);
      }, 10000);

      const response =
        await handleGraphqlResponse<TriggerBusinessDataLongFetchNewMutation>(
          () =>
            client.graphql({
              query: triggerBusinessDataLongFetchNew,
              variables: {
                triggerBusinessDataLongFetchInput: {
                  business_id: businessId,
                },
              },
            }),
        );

      if (response.error) {
        Sentry.captureException(response.error);
        set((state) => ({ ...state, asyncFetchLoading: false }));
        return;
      }

      const subscription = (await client.graphql({
        query: onDataRefreshedForBusiness,
        variables: {
          data: businessId,
        },
      })) as GraphqlSubscriptionResult<OnDataRefreshedForBusinessSubscription>;

      const selectedBusiness = get().selectedBusiness;

      const handler = subscription.subscribe({
        next: async () => {
          console.log('onDataRefreshedForBusiness');
          await clearCache(businessId);
          if (selectedBusiness && refreshAferFetch) {
            const adAccountSettings = selectedBusiness.ad_account_settings.map(
              (m) => {
                m.last_data_refreshed = dayjs().format();
                return m;
              },
            );
            selectedBusiness.ad_account_settings = adAccountSettings;
            set((state) => ({
              ...state,
              selectedBusiness: {
                ...selectedBusiness,
                ad_account_settings: selectedBusiness.ad_account_settings.map(
                  (setting) => ({
                    ...setting,
                    last_data_refreshed: dayjs().format(
                      'YYYY-MM-DD HH:mm:ss.SSS Z',
                    ),
                  }),
                ),
              },
            }));
          }
          if (refreshAferFetch) {
            set((state) => ({ ...state, fetchComplete: true }));
          }

          if (get().listenerCount <= 0) {
            set((state) => ({
              ...state,
              asyncFetchLoading: false,
            }));
            setTimeout(() => {
              set((state) => ({
                ...state,
                displayLoader: false,
              }));
            }, 1000);
          }
        },
        error: (error) => Sentry.captureException(error),
      });

      set((state) => ({ ...state, subscriptionHandler: handler }));
      console.log('🚀 ~ triggerFetch: ~ handler:', handler);
    } catch (error) {
      console.log('some error', error);
      set((state) => ({ ...state, displayLoader: false }));
      Sentry.captureException(error);
    }
  },
  addListener: () =>
    set((state) => ({ ...state, listenerCount: state.listenerCount + 1 })),
  removeListener: () => {
    set((state) => ({ ...state, listenerCount: state.listenerCount - 1 }));
    if (get().listenerCount === 1) {
      set((state) => ({
        ...state,
        asyncFetchLoading: false,
      }));
    }
  },
});
