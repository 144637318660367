import { SnackBarState } from '@interfaces/snackbar';
import { StateCreator } from 'zustand';

interface State {
  snackBar: SnackBarState;
}

export const initialSnackBarSlice: State = {
  snackBar: {
    display: false,
    message: 'hello there',
    severity: 'success',
  },
};

interface Actions {
  setSnackBar: (snackBar: SnackBarState) => void;
}

export interface SnackBarSlice extends State, Actions {}

export const createSnackBarSlice: StateCreator<SnackBarSlice, [], []> = (
  set,
) => ({
  ...initialSnackBarSlice,
  setSnackBar: (snackBar) => set((state) => ({ ...state, snackBar })),
});
