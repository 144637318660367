import { useBoundStore } from '@store/index';
import { GraphQLResult } from 'aws-amplify/api';
import { UnknownGraphQLResponse } from '@aws-amplify/api-graphql';
import { Cache, CacheConfig } from '@aws-amplify/core';

const config: CacheConfig = {
  keyPrefix: 'Sirge',
  itemMaxSize: 3000,
  capacityInBytes: 1024 * 1000, // 1024000 ~= 1mb
  defaultTTL: 120 * 1000,
  warningThreshold: 1,
  defaultPriority: 1,
};

Cache.createInstance(config);

export const CacheCall = async <T>(
  operation: () => Promise<GraphQLResult<T>> | UnknownGraphQLResponse,
  key: string,
): Promise<GraphQLResult<T> | UnknownGraphQLResponse> => {
  const cachedData = await Cache.getItem(key);

  if (cachedData) {
    await Cache.removeItem(key);
    await Cache.setItem(key, cachedData, { expires: Date.now() + 1000 * 120 });
    return cachedData;
  }

  const response = await operation();

  await Cache.setItem(key, response, { expires: Date.now() + 1000 * 120 });

  return response;
};

export const clearCacheKey = async (key: string) => {
  await Cache.removeItem(key);
};

export const clearCacheKeyWithPartialKey = async (
  partial_key: string,
  business_id: string,
) => {
  const cachedKeys = await Cache.getAllKeys();

  const proms = cachedKeys
    .filter((a) => a.includes(partial_key) && a.includes(business_id))
    .map(Cache.removeItem);
  await Promise.all(proms);
};

export const clearCache = async (business_id: string) => {
  const cachedKeys = await Cache.getAllKeys();

  const proms = cachedKeys
    .filter((a) => a.includes(business_id))
    .map(Cache.removeItem);
  await Promise.all(proms);
};
